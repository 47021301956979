<template>
  <div>
    <Result :imgUrl="withdrawalImg" title="取款成功" />
  </div>
</template>

<script>
import withdrawalImg from "@/assets/images/withdrawal_success.png";
export default {
  name: "WithdrawalSuccess",
  data() {
    return {
      withdrawalImg,
    };
  },
};
</script>
